<script lang="ts">
	export let bg: string = 'bg-primary-500';
	export let pulseBg: string = 'bg-primary-400';
	export let size: string = 'h-4 w-4';
</script>

<span class="relative flex {size} ">
	<span class="absolute inline-flex h-full w-full animate-ping rounded-full {pulseBg} opacity-75"
	></span>
	<span class="relative inline-flex {size} rounded-full {bg}"></span>
</span>
